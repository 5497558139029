.management-menu {
  position: fixed;
  top: 52px;
  bottom: 40px;
  right: 0;
  width: 500px;
  z-index: 9999999;
  background-color: $basicFontColor;
  color: white;
  transition: 0.3s linear;
  transform: translate3d(100%, 0, 0);
  @include mq("phone-large") {
    width: 100%;
  }
  @include menuUserMargin;
  &.show {
    transform: translate3d(0, 0, 0);
  }
  .container,
  .manage-details {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    div.option:nth-of-type(1) {
      padding: 50px 50px 10px 50px;
    }
    .error {
      color: red;
    }
    .success {
      color: $completed;
    }
    .option {
      padding: 10px 50px;
      text-transform: uppercase;
      font-size: 15px;
      &.pt-0 {
        padding: 0 50px 50px 50px !important;
      }
      &.ptb-10 {
        padding: 10px 50px 10px 50px !important;
      }
      &-detail {
        display: flex;
        align-content: center;
        cursor: pointer;
        a.center {
          display: flex;
          align-items: center;
        }
      }
      @include mq("phone-large") {
        padding: 20px 50px;
      }

      .doctor-hovered,
      .patient-hovered {
        display: none;
      }
      img {
        height: 20px;
        width: 20px;
        margin-right: 20px;
        &.patient-icon {
          height: 20px;
          width: 20px;
        }
        &:hover + .doctor-hovered,
        &:hover + .patient-hovered {
          position: absolute;
          height: 25px;
          width: 400px;
          top: 30px;
          left: 20px;
          display: grid;
          place-items: center;
          border-radius: 20px;
          color: white;
          z-index: 999;
          font-weight: bold;
          background-color: $borderColor;
        }
        &:hover + .patient-hovered {
          top: 45px;
        }
        &.resend-invitation-envelope-icon {
          height: 15px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      a {
        color: white;
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;
      .option {
        padding: 20px 0 0 0 !important;
      }
    }
    .manage-details {
      position: absolute;
      z-index: 9;
      top: 0;
      bottom: 0;
      right: -100%;
      width: 100%;
      height: unset;
      background-color: $basicFontColor;
      color: white;
      transition: 0.3s linear;
      @include menuUserMargin;
      @include menuButton;
      &.show {
        right: 0;
      }
      .container-overflow {
        @include customScrollBar;
        overflow-y: scroll;
        height: calc(100% - 100px);
        width: 100%;
        &.middle-container {
          margin-top: 100px;
          height: calc(100% - 200px);
          @include mq("main-table") {
            position: absolute;
            top: 0;
            bottom: 20%;
            left: 0;
            height: initial;
            &.m-margin-top50 {
              margin-top: 50px !important;
            }
          }
        }
      }
      .change-password,
      .edit-global-form,
      .edit-individual-form,
      .edit-doctor-data,
      .resend-invitation-doctor,
      .delete-doctor-data,
      .edit-patient-data,
      .edit-patient-parents-data,
      .resend-invitation-patient {
        margin-top: 40px;
        input {
          width: 100%;
          margin-top: 10px;
          @include mq("main-table") {
            width: 70vw;
          }
          &.patient-dob {
            margin-left: 30px;
          }
        }
        &.mt-0 {
          margin-top: 0;
        }
        select {
          @include customSelect;
          &.related-doctor {
            margin-left: 20px;
          }
        }
        .buttons {
          position: absolute;
          top: 450px;
          left: 50%;
          transform: translateX(-50%);
          &.delete {
            display: block;
            position: static;
            margin-bottom: 20px;
            text-align: center;
          }
          &.patient-edit {
            top: 650px;
          }
          &.patient-parents-edit,
          &.doctor-edit,
          &.patient-edit-button,
          &.parent-invitation-resend {
            display: block;
            position: static;
            margin-top: 50px;
            margin-bottom: 20px;
            text-align: center;
            transform: translateX(0);
          }
        }
        label {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 20px;
          color: white;

          input {
            width: auto;
            margin-top: 0;
            margin-right: 15px;
          }
        }
        .parent-invitation-send-container {
          width: 100%;
          .parent-container {
            font-size: 14px;
            .bold {
              font-weight: bold;
            }
            .parent-info {
              margin-bottom: 20px;
              padding-bottom: 5px;
              font-size: 20px;
              border-bottom: 1px solid white;
            }
            .fullname-container {
              margin-bottom: 20px;
            }
            .last-invitation-container,
            .fullname-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
          .parent-container:nth-last-child(1) {
            margin-top: 40px;
          }
        }
      }
      @include mq("main-table") {
        .edit-patient-data {
          margin-top: 0 !important;
        }
      }
      .edit-patient-parents-data {
        @include mq("main-table") {
          width: 100% !important;
          .iti {
            width: 100% !important;
          }
          #edit-patient-parent1-phone,
          #edit-patient-parent2-phone {
            width: 78vw;
          }
        }
        div.parent:nth-last-of-type(4) {
          margin-top: 70px;
        }
        .parent-fullname {
          width: calc(100% + 30px);
          margin-bottom: 20px;
          border-bottom: 1px solid white;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          @include mq("main-table") {
            width: 100%;
          }
        }
        img.email-confirmed,
        img.email-not-confirmed {
          height: 15px;
          width: 15px;
        }
        div.email-confirmed {
          display: inline-block;
          font-size: 10px;
        }
      }

      .edit-doctor-data {
        .buttons {
          top: 600px;
        }
      }
      .resend-invitation-doctor,
      .resend-invitation-patient {
        width: 100%;
        .hide {
          display: none !important;
        }
        .container {
          display: flex;
          align-items: center;
          .info {
            border-bottom: 1px solid white;
            font-size: 23px;
            margin-bottom: 50px;
          }
          .date {
            font-size: 20px;
          }
          img {
            &.clock {
              margin-right: 0;
              margin-bottom: 50px;
              transform: scale(3.5);
            }
          }
        }
      }
      .delete-doctor-data,
      .delete-patient-parent-data,
      .delete-patient-data,
      .edit-global-form,
      .edit-individual-form {
        margin-top: 0;
        margin-left: 20px;
        .info-container {
          border: 1px solid white;
          padding: 10px;
          text-align: center;
          & div:nth-of-type(1) {
            margin-bottom: 10px;
            & > span:nth-of-type(1) {
              border-bottom: 1px solid white;
            }
          }
        }
        .patient-list {
          text-align: left;
          li {
            margin-top: 10px;
            counter-increment: li;
          }
          li::before {
            content: counter(li);
            display: inline-block;
            width: 1em;
            margin-right: 5px;
            padding-right: 10px;
          }
        }
      }
      .forgot-pass,
      .delete-parent-cont {
        margin-top: 20px;
        height: 10px;
        font-size: 10px;
        width: 100%;
        text-align: right;
        img {
          height: 10px;
          width: 10px;
          margin-right: 0;
        }
      }
      .password-change-info,
      .global-settings-section-info,
      .individual-settings-section-info {
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        font-weight: bold;
        font-size: 13px;
        text-align: center;
        img.error {
          width: 30px;
          height: 35px;
          margin-right: 0;
        }
        img.success {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
        &.show {
          display: flex;
        }
        &.mt-20 {
          margin-top: 20px;
        }
      }
    }
  }
  .top-stick {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid white;

    .option {
      padding-bottom: 30px;
      &-detail {
        justify-content: center;
      }
    }
  }
  .bottom-stick {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;
    .option {
      flex-basis: 50%;
      padding: 30px 50px 50px 50px !important;
      @include mq("main-table") {
        padding: 30px 50px 50px 50px !important;
      }
    }
  }
}
#send-delete-doctor-data-modal-buttons {
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}

img.error {
  height: 14px !important;
  width: 14px !important;
  padding-right: 5px;
}

.close-all-modals {
  flex-basis: 50%;
  padding-right: 50px;
  display: flex;
  justify-content: flex-end;
  .option-detail > img:nth-of-type(1) {
    margin-right: 0;
  }
}

.patient-delete {
  text-align: center;
}

.hide-element {
  display: none !important;
}

@include mq("main-table") {
  .edit-patient-parents-data-modal + .edit-patient-parents-data {
    width: 75vw;
  }
}

#edit-doctor-data-modal {
  .edit-doctor-data {
    margin-top: 0;
  }
  #edit-doctor-email {
    margin-bottom: 10px;
  }
  .iti {
    width: 100%;
  }
  @include mq("main-table") {
    #edit-doctor-phone {
      width: 77vw;
    }
  }
}
