@import "variables";
@import "mixins";
@import "media_query";
@import "general";
@import "menu-bar";
@import "toolbar";
@import "conversation";
@import "login";
@import "account-archived";
@import "patient-list";
@import "patient-form";
@import "header";
@import "messages";
@import "form";
@import "datepicker";
@import "forms-dashboard";
@import "timepicker";
@import "form-footer-management";
@import "loader";
@import "pdf-styles";
@import "office";
@import "intl-tel-input/build/css/intlTelInput.css";
@import "admin";
@import "custom-dropdown";
