.menu-container {
  button {
    border: none;
    background-color: inherit;
  }

  &.relative-parent {
    position: relative;
    display: flex;
  }

  .menu {
    background-color: #fff;
    padding: 15px 10px;
    position: absolute;
    width: 150px;
    right: 100%;
    display: none;
    z-index: 9999;

    &.open {
      display: block;
    }

    li {
      padding: 10px 0;
      text-align: center;
      border-bottom: 1px solid $accentTurquoise;
      &:last-of-type {
        border-bottom: none;
      }

      a {
        color: $basicFontColor;
        transition: 0.3s;
        display: block;
        font-size: 16px;
        text-align: center;

        &:hover {
          color: $accentTurquoise;
        }
      }
    }
  }
}

.user-menu {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 20px;
  align-items: center;
  .name {
    margin: 0 20px 0 10px;
    text-transform: uppercase;
    color: $basicFontColor;
  }
}
