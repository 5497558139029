.forms-dashboard {
  margin-top: 0;

  .basic-border {
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 80px);
  }

  .header {
    margin-bottom: 40px;
  }

  h1 {
    color: $accentTurquoise;
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    color: $accentTurquoise;
  }

  ul {
    display: grid;
    padding: 0;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-column-gap: 1.5%;
    grid-row-gap: 30px;
    width: 100%;

    .href-container {
      background-color: $accentTurquoise;
      text-align: center;
      height: 75px;
      border-radius: 20px;

      a {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        transition: 0.4s;

        &:hover {
          color: $basicFontColor;
        }
      }
    }

    .info {
      color: $basicFontColor;
      font-size: 14px;

      span {
        display: block;
        margin: 10px auto 0;
        text-align: center;

        &.fa {
          font-size: 120%;
          display: inline;
          padding-right: 5px;
        }

        &.success {
          color: $accentTurquoise;
        }
      }
    }
  }
}
