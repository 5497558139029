.conversation {
  position: relative;

  &-section {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 25%;
    top: 60px;
    width: 440px;
    height: 350px;
    border-radius: 20px;
    z-index: 9;
    transform: translateX(-50%) scaleY(0);
    background: $conversationBackground;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: 0.15s linear;
    transform-origin: 50% -20px;
    justify-content: flex-end;

    &.open {
      transform: translateX(-50%) scaleY(1);
    }
    @include mq("main-table") {
      position: fixed;
      left: 2vw;
      right: 0;
      width: 96vw;
      height: calc(60vh - 65px);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 1);
      &.open {
        transform: scaleY(1);
      }
    }
    &::before {
      height: 15px;
      width: 15px;
      position: absolute;
      top: -7px;
      left: 50%;
      content: "";
      background: $conversationBackground;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      transform: translateX(-50%) rotate(-135deg);
      @include mq("main-table") {
        display: none;
      }
    }

    &::after {
      height: 15px;
      width: 50px;
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: $conversationBackground;
      @include mq("main-table") {
        display: none;
      }
    }

    &.admin {
      height: 390px;
      top: auto;
      bottom: calc(100% + 30px);
      left: calc(50% - 115px);
      transform: translate(-50% 0);
      @include mq("main-table") {
        left: -46vw;
        right: 0;
        width: inherit;
        height: inherit;
        top: -800%;
        bottom: 0;
      }
      .header {
        line-height: 40px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        background-color: $lightFontColor;
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        text-align: center;
        justify-self: flex-end;
        position: absolute;
        top: 0;
        z-index: 9999;
      }
      .x-button {
        display: grid;
        place-items: center;
        position: absolute;
        top: 20px;
        right: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border: none;
        z-index: 9999;
      }
      &::before {
        top: unset;
        bottom: -7px;
        left: unset;
        right: 90px;
        content: "";
        background: $conversationBackground;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        transform: translateX(-50%) rotate(-135deg);
      }
      &::after {
        top: unset;
        bottom: 0;
        left: 76%;
      }
    }

    .message-container {
      @include customScrollBar;
      display: flex;
      flex-direction: column-reverse;
      overflow-y: scroll;
      margin-top: 5vh;
      &.admin {
        max-height: 31vh;
        @include mq("custom-mobile") {
          max-height: 57vh;
        }
      }

      .message {
        box-sizing: border-box;
        padding-bottom: 15px;
        display: flex;

        .message-bubble {
          height: 50px;
          flex-basis: 50px;
          border-radius: 50%;
          background-image: $darkGradient;
          color: white;
          text-transform: uppercase;
          line-height: 50px;
          text-align: center;
          font-size: 20px;
        }

        .message-content {
          position: relative;
          color: white;
          margin: 25px 80px 0 25px;
          flex-basis: 230px;
          background-color: $conversationSenderBackground;
          border-radius: 20px;
          text-align: left;
          padding: 15px;

          &::after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: -10px;
            width: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $conversationSenderBackground;
          }
        }
        .seen {
          position: absolute;
          color: $lightFontColor;
          bottom: -15px;
          right: 10px;
          font-size: 10px;
        }
        .old_doctor {
          position: absolute;
          color: $lightFontColor;
          bottom: -27px;
          right: 10px;
          font-size: 10px;
        }
        &.recipient {
          padding-left: 15px;
        }

        &.sender {
          flex-direction: row-reverse;
          padding-right: 10px;

          &:nth-child(1) {
            margin-bottom: 15px;
          }

          & .message-bubble {
            background-image: none;
            background-color: $lightFontColor;
          }

          & .message-content {
            margin: 25px 25px 0 25px;
            background-color: $conversationReceiverBackground;
            word-break: break-all;
            &::after {
              content: "";
              position: absolute;
              display: block;
              top: 0;
              left: 88%;
              width: 0;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 20px solid $conversationReceiverBackground;
            }
          }
        }
        .attachment {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          & span,
          & div {
            margin-left: 10px;
            overflow-wrap: anywhere;
            color: white;
            font-weight: bold;
            justify-self: flex-start;
            width: 100%;
          }
          & .download {
            position: relative;
            background-color: white;
            height: 40px;
            flex-basis: 40px;
            border-radius: 50%;
            flex-shrink: 0;
            margin-left: 10px;
            & a > img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform-origin: center center;
              transform: translate(-50%, -50%) scale(1.2);
            }
          }
        }
      }
    }
    .attachment-message-container {
      @extend .message-container;
      margin-bottom: 5vh;
      max-height: 36vh;
      @include mq("custom-mobile") {
        max-height: 62vh;
      }
    }
  }

  &-user {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    position: relative;

    .message-short {
      position: absolute;
      bottom: 7px;
      left: 18%;
      font-size: 11px;
      color: red;
      display: none;
      &.show {
        display: block;
      }
    }

    label {
      margin-right: 10px;
      cursor: pointer;
    }

    input.message-text {
      flex-basis: 65%;
      border-radius: 20px;
    }
    .loaded {
      display: flex;
      flex-direction: column;
      width: 70%;

      .attachment-container {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        &.invisible {
          display: none;
        }
        .attachment-icon {
          position: relative;
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          flex-grow: 0;
          margin-right: 20px;
          border-radius: 50%;
          background-color: $conversationReceiverBackground;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .attachment-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-basis: 70%;
          overflow-wrap: anywhere;
          color: $conversationReceiverBackground;
          font-weight: bold;
        }
        .attachment-delete {
          flex-shrink: 0;
          flex-grow: 0;
          flex-basis: 15%;
          margin-left: 10px;
          img {
            cursor: pointer;
          }
        }
      }
    }

    .arrow {
      height: 30px;
      width: 30px;
      background-color: $conversationReceiverBackground;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-left: 10px;
      cursor: pointer;
    }
    .loaded {
      display: flex;
    }
  }

  &-unread {
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: red;
    bottom: 0;
    left: 0;
    z-index: 91;
    transform: scale(0);
    transition: 0.3s linear;
    &.show {
      transform: scale(1);
      animation: newMessage 1s infinite alternate;
    }
  }
  @keyframes newMessage {
    0% {
      transform: scale(0.2);
    }
    100% {
      transform: scale(1);
    }
  }

  &-empty {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 270px;
    width: 100%;
    color: $basicFontColor;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 150px;
    @include mq("main-table") {
      width: 100%;
    }
  }
}

.attachment-conversation {
  @extend .conversation;
}

.attachment-icon {
  height: 20px;
}

.single-user-message {
  position: relative;
  .conversation-unread {
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: red;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
}
.chat-bubble-container {
  position: relative;
  .chat-bubble {
    position: fixed;
    bottom: 10%;
    right: 200px;
    transform: translate(calc(100% + 15px), 40px) scale(0);
    transition: 0.2s linear;
    z-index: 9;
    @include mq("main-table") {
      transform: translate(100%, 0) scale(1);
    }
    .bubble {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background-color: $conversationReceiverBackground;
      display: grid;
      place-items: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    &.open {
      transform: translate(calc(100% + 15px), 40px) scale(1);
      @include mq("main-table") {
        transform: translate(100%, 0) scale(1);
      }
    }
    @include mq("main-table") {
      width: 95%;
      .bubble {
        visibility: hidden;
      }
    }
  }
}
input {
  color: $basicFontColor;
}
