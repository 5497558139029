#header {
  background-image: $adminGradent;
}

.admin-icons {
  box-sizing: border-box;
  h2,
  h1 {
    color: $basicFontColor;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid $basicFontColor;
    font-size: 20px;
    margin: 20px 0;
    &::after {
      content: "";
      height: 2px;
      width: 100%;
      background-color: $borderColor;
      display: block;
    }
  }
  h1 {
    font-size: 24px;
    text-align: center;
  }
  ul {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    flex-shrink: 0;
    li {
      //flex-basis: 150px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75px;
      list-style-type: none;
      margin: 10px;
      background-image: $adminGradent2;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
      font-size: 12px;
      &:hover {
        box-shadow: 2px 2px 25px rgb(0, 0, 0);
        cursor: pointer;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 100%;
        i {
          margin-left: 10px;
        }
        span {
          margin-left: 5px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}

.breadcrumbs,
#changelist-filter > h2 {
  background-image: $adminGradent !important;
  box-sizing: border-box !important;
}

li {
  a {
    &.addlink,
    &.historylink {
      background-color: red !important;
      font-weight: 700 !important;
      font-size: 15px !important;
      border: 2px solid white;
      margin-top: -10px !important;
    }
  }
}

.submit-row {
  input {
    background-color: #5a8297 !important;
    border: none !important;
    text-transform: uppercase !important;
  }
}

input[name="_confirm_action"],
input[name="_save"] {
  background-color: #5a8297 !important;
  border: none !important;
  text-transform: uppercase !important;
}

input[name="_addanother"],
input[name="_continue"] {
  background-color: #5a8297 !important;
  border: none !important;
  text-transform: uppercase !important;
}

#main {
  .content {
    min-height: auto;
  }
}
