.patient-list {
  margin-bottom: 40px;
  padding-top: 40px;
  @include mq("main-table") {
    box-sizing: border-box;
    padding: 5px;
  }

  .basic-border {
    margin-top: 0;
    transition: 0.3s linear;
    &.blured {
      filter: blur(2px);
    }
  }
  .child-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-grow: 1;
    .child-button {
      box-sizing: border-box;
      flex-grow: 1;
      flex-basis: 25%;
      padding: 30px;
      margin: 30px;
      background-color: $accentTurquoise;
      border-radius: 20px;
      cursor: pointer;
      color: white;
      text-transform: uppercase;
      text-align: center;
      box-shadow: 10px 10px 10px 1px rgba($borderColor, 0.5);
      display: grid;
      place-items: center;
      &.archived {
        background-color: $borderColor;
        cursor: not-allowed;
        .small {
          font-size: 12px;
        }
      }
    }
  }
  .filters {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
    color: $lightFontColor;
    text-transform: uppercase;
    font-size: 12px;
    @include mq("main-table") {
      .suffix {
        width: 100%;
      }

      .search-submit {
        position: absolute;
        top: 0;
        right: 25px;
        bottom: 0;
      }
      #search_input {
        width: 100%;
        border-radius: 20px 20px 20px 20px;
      }
      .search-submit {
        right: 0;
      }
    }
    .filter {
      display: flex;
      flex: auto;
      align-items: center;

      img {
        max-height: 14px;
        margin-right: 10px;
      }

      select {
        appearance: none;
        border: none;
        background-image: url("../../img/arrow-down.png");
        background-repeat: no-repeat;
        background-position: 60px 60%;
        margin-left: 15px;
        padding: 5px 19px 5px 19px;
        text-align: center;
        text-transform: uppercase;
        border-radius: 12px;
        width: 85px;
        max-height: 25px;
        color: $lightFontColor;
      }

      &.search {
        justify-content: flex-end;

        .suffix {
          height: 100%;
        }

        input {
          color: $lightFontColor;
          font-size: 14px;
          padding: 13px;
          border-radius: 20px 0 0 20px;
          max-width: 320px;
          width: 100%;

          &.width-250 {
            width: 250px;
            @include mq("main-table") {
              width: initial;
            }
          }
        }

        button {
          height: 100%;
          background-color: $accentTurquoise;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 0 20px 20px 0;
          padding: 12px 20px;

          img {
            max-height: 23px;
          }
        }
      }
    }
    @include mq("main-table") {
      justify-content: center;
      input {
        font-size: 16px;
      }
    }
  }

  h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  h4 {
    text-transform: uppercase;
    text-align: center;
    color: $lightFontColor;
    margin: 0;
    font-size: 17px;
  }

  .user-detail {
    color: $basicFontColor;
    margin: 20px 50px;
    .user {
      text-transform: uppercase;
      color: $lightFontColor;
      font-weight: 600;
    }
    div {
      padding: 2px 0 0 30px;
    }
  }

  .section-register {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $basicFontColor;
    h4 {
      color: $accentTurquoise;
    }
    p {
      text-transform: inherit;
      margin: 0;
    }
    .send {
      width: 150px;
      margin: 30px 0;
    }
  }

  .delete-user-modal,
  .download-modal,
  .pdf-modal,
  .resend-invitation,
  .delete-confirmation-user-modal {
    display: none;
    height: 350px;
    width: 500px;
    background-color: $conversationBackground;
    border: 2px solid;
    z-index: 999;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
    @include mq("main-table") {
      width: 95%;
    }
    &.show {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: blur(0px);

      & > h2 {
        font-size: 22px;
        font-weight: bold;
      }
      & > span {
        margin: 20px 0;
      }
      .buttons > * {
        margin-top: 30px;
        text-transform: uppercase;
        color: #fff;
        background-color: $accentTurquoise;
        font-size: 18px;
        font-weight: 500;
        padding: 5px 20px;
        border-radius: 4px;
        border: unset;
        &.cancel {
          background-color: lightgray;
          margin-left: 50px;
        }
        &.delete {
          background-color: red;
        }
      }
    }
    .x-button {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      height: 30px;
      width: 30px;
      background-color: $lightFontColor;
      border-radius: 50%;
      text-align: center;
      color: white;
      font-weight: bold;
      font-size: 16px;
      & > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    p {
      color: $basicFontColor;
    }
    &.invitation {
      .x-button {
        z-index: 9;
      }
      #resend-patient-name {
        display: block;
        position: absolute;
        top: 15px;
        left: 50%;
        margin: 0;
        width: 80%;
        transform: translateX(-50%);
      }
      .parent-section {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 50px;
        justify-content: center;
        .parent {
          &:nth-child(1) {
            margin-bottom: 25px;
          }
          .name {
            margin-top: 0;
            font-weight: bold;
          }
          .buttons {
            text-align: center;
            & > * {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .delete-user-modal {
    .buttons {
      display: flex;
      justify-content: space-between;
      #cancel {
        margin-left: 10px;
      }
      & > * {
        margin: 0 10px;
      }
    }
  }
  .list-container {
    padding: 0 40px;
    position: relative;
    @include mq("main-table") {
      padding: 0;
    }
  }

  .table-header,
  .table-row {
    display: grid;
    grid-template-columns: 21% 18% 13% 6% 9% 12% 6% 5% 8%;
    .patient-details-grid {
      display: none;
      @include mq("main-table") {
        display: grid;
        height: 50vh;
        grid-auto-flow: row;
        grid-template-columns: 100%;
        grid-template-rows: repeat(auto-fill, 50px);
        border: 2px solid $borderColor;
      }
    }
    .table-delete {
      cursor: pointer;
      z-index: 8;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: inherit;
        margin-left: 10px;
        border: none;
      }
      &.last-email-sent {
        font-size: 10px;
        color: $basicFontColor;
      }
    }
    .pdf-icon {
      width: 10px;
      height: auto;
      cursor: pointer;
    }
  }

  @include mq("main-table-from-mobile") {
    .patient-name-wrapper {
      display: flex !important;
      align-items: center;
      justify-content: flex-start !important;
      width: 100%;
    }
  }
  @include mq("main-table") {
    .mobile-to-show {
      display: inherit !important;
    }
    .roll-ups {
      img {
        height: 10px;
        margin-right: 15px;
        transition: 0.35s linear;
        &.rotate-180 {
          transform: rotate(180deg);
        }
      }
    }
    .table-header {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      padding: 50px 50px;
      text-align: center;
      place-items: center;
      & div {
        display: grid;
        grid-template-columns: 50% 25% 25%;
        font-size: 16px;
        margin: 10px;
        width: 40%;
        justify-items: left;
        margin-left: 0 !important;
        :nth-last-child(1) {
          justify-self: right;
        }
        button {
          justify-self: left;
        }
        .datepicker-mobile {
          padding-right: 10px !important;
        }
      }
    }
    .table-row {
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      height: auto !important;
      &.patient-row-mobile {
        display: flex !important;
        color: red;
        flex-direction: row;
        height: auto !important;
        width: auto;
      }
      .resend-invitation {
        width: 100%;
      }
      img.patient-toggle-bar {
        width: 30px !important;
      }
      .date,
      .doctor-item,
      .status-container,
      .single-user-message,
      .m-resend-invitation,
      .m-pdf,
      .delete-archive-patient {
        justify-content: space-between;
        padding: 0 10px !important;
        border: 0 solid white !important;
        .m-doctor {
          width: auto;
        }
      }
      .m-bold {
        font-size: 150%;
        font-weight: bold;
      }
      .m-lineheight-unset {
        line-height: 18px !important;
      }
      .patient-fullname {
        margin-left: 20px;
      }
      .pdf-icon {
        width: 20px;
        padding-right: 7px;
        height: auto;
      }
      .action-icon {
        width: 15px;
        padding-right: 7px;
        height: auto;
      }
    }
    .doctor-modal,
    .patient-modal {
      &.show {
        position: relative !important;
        justify-self: center;
        left: 30px !important;
        top: 120% !important;
        display: block;
      }
    }
  }

  .filter {
    &-tags {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      box-sizing: border-box;
      @include mq("main-table") {
        flex-wrap: wrap;
        width: initial;
        flex-basis: 33%;
      }
    }
    &-tag {
      position: relative;
      color: $basicFontColor;
      width: auto;
      display: inline-block;
      font-size: 14px;
      margin: 10px 10px 10px 0;
      border-radius: 20px;
      padding: 5px 80px 5px 20px;
      border: 1px solid $accentTurquoise;
      background-color: white;
      &:nth-last-child(1) {
        margin: 10px 0;
      }
      span {
        font-weight: bold;
        margin-left: 10px;
      }
      .tag_reset {
        position: absolute;
        top: 15%;
        right: 10px;
        z-index: 1;
        background-color: $accentTurquoise;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        color: white;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .table-header-forms,
  .table-row-forms {
    display: grid;
    grid-template-columns: 6fr 4fr;
    grid-template-rows: 100%;
    position: relative;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .toggle-btn-circle {
      position: absolute;
      top: 50%;
      right: 10px;
      height: 30px;
      width: 30px;
      background-color: white;
      border-radius: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -25%);
        &.hide-table-content {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }
  }

  .table-header,
  .table-header-forms {
    background-color: $lightFontColor;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    padding: 15px 0;
    .ml-30 {
      margin-left: 30px;
    }
    .left {
      justify-content: flex-start;
    }
    .doctor,
    .patient {
      &-section {
        margin-left: 5px;
        cursor: pointer;
        position: relative;
        img {
          height: 18px;
        }
        .patient-img {
          height: 20px;
        }
      }

      &-modal {
        position: absolute;
        top: 0;
        left: 20px;
        transform: translateY(-25%);
        height: 50px;
        width: 200px;
        background-color: white;
        border: 1px solid black;
        z-index: 9;
        display: none;
        &.patient {
          margin-left: 15px;
        }
        &.show {
          display: block;
        }
        @include customSelect;
      }
    }
  }

  .table-content-forms {
    overflow: hidden;
  }

  .table-content,
  .table-content-forms {
    box-sizing: border-box;
    position: relative;
    transition: 1s linear;
    max-height: 2000px;
    .table-row,
    .table-row-forms {
      background-color: #fff;
      color: $basicFontColor;
      font-size: 14px;
      padding: 0;
      margin-top: 0;
      height: 35px;
      border-bottom: 2px solid $listMarginBottom;
      position: relative;
      img.patient-toggle-bar {
        width: 20px;
        margin-right: 5px;
        cursor: pointer;
      }
      .status-container {
        position: relative;
      }
      .status-hovered {
        display: none;
      }
      .archived {
        color: $lightFontColor !important;
        cursor: pointer;
        &:hover + .status-hovered {
          position: absolute;
          height: 25px;
          width: 80px;
          top: 0;
          right: -90px;
          display: grid;
          place-items: center;
          border-radius: 20px;
          color: white;
          z-index: 2;
          font-weight: bold;
          background-color: $borderColor;
        }
      }
      .completed {
        color: $completed !important;
        cursor: pointer;
        &:hover + .status-hovered {
          position: absolute;
          height: 25px;
          width: 200px;
          top: 0;
          right: -220px;
          display: grid;
          place-items: center;
          border-radius: 20px;
          color: white;
          z-index: 2;
          font-weight: bold;
          background-color: $borderColor;
        }
      }
      .danger {
        color: red !important;
      }
      & .left {
        justify-self: flex-start;
        margin-left: 30px;
        text-transform: uppercase;
        &.ml-50 {
          margin-left: 50px;
        }
        a {
          color: $accentTurquoise;
          font-weight: 500;
        }
      }
      .success {
        color: $completed;
      }
      &.empty-table {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        text-transform: uppercase;
      }

      .date {
        color: $lightFontColor;
      }

      .send-reminder {
        text-transform: uppercase;
        color: #fff;
        background-color: $accentTurquoise;
        font-size: 12px;
        font-weight: 500;
        padding: 1px 8px;
        border-radius: 4px;
      }
      &.patient-name-wrapper {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        padding-left: 5px;
        justify-self: flex-start;
        position: relative;
        @include mq("main-table") {
          justify-content: space-between;
          width: 100%;
          height: 50px !important;
          background-color: white;
          box-shadow: 8px 7px 11px 2px rgba(0, 0, 0, 0.8);
          border: 2px solid $borderColor;
        }
        .patient-fullname {
          cursor: pointer;
          line-height: 10px;
        }

        @include mq("main-table") {
          .modal-upper-border {
            display: none;
          }
          .x-button {
            position: fixed !important;
            top: 5% !important;
            background-color: #3c5153;
            height: 40px;
          }
          .form-section {
            flex-wrap: nowrap !important;
            position: fixed !important;
            left: 5px !important;
            right: 0 !important;
            bottom: 0 !important;
            height: 50vh !important;
            margin-bottom: 20px;
            @include customScrollBar;
            overflow: scroll;
            &::before {
              display: none;
            }
          }
        }

        .form-section,
        .patient-detail-section,
        .doctor-detail-section {
          position: absolute;
          height: 235px;
          width: 400px;
          background-color: $modalColor;
          z-index: 999;
          top: 50%;
          right: -420px;
          transform: translateY(-50%) scale(0);
          transform-origin: 0 50%;
          transition: 0.35s;
          border: 1px solid $accentTurquoise;
          box-shadow: 0px 0px 10px 0px $basicFontColor;

          &.active {
            transform: translateY(-50%) scale(1);
          }
          //flex
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-start;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -15px;
            left: -30px;
            border: solid 15px transparent;
            border-right-color: $accentTurquoise;
          }
          .wrapper {
            width: 42%;
            padding: 2px 15px;
            text-align: left;
            color: $basicFontColor;
            @include mq("main-table") {
              width: 100%;
              margin: 10px 0 10px 10px;
              font-size: 20px;
            }

            .success {
              color: $accentTurquoise;
            }
            .error {
              color: red;
            }
            & > a {
              text-decoration: none;
              display: inline-block;
              width: 100%;
              padding-left: 5px;
              text-align: left;
              border-right-color: $basicFontColor;
            }
          }
          .modal-upper-border,
          .patient-detail-section > .header {
            position: absolute;
            right: -1px;
            left: -1px;
            top: -20px;
            bottom: 100%;
            background-color: $accentTurquoise;
            z-index: 999;
            color: white;
            text-transform: uppercase;
          }
          @include mq("main-table") {
            .modal-upper-border {
              top: -50px;
              font-size: 20px;
            }
          }
          .x-button {
            position: absolute;
            height: 20px;
            width: 20px;
            right: 5px;
            top: -20px;
            z-index: 999;
            font-weight: bold;
            color: white;
            cursor: pointer;
            @include mq("main-table") {
              transform: scale(2);
              top: -35px;
              right: 20px;
            }
          }
          .patient-detail-section {
            position: absolute;
            top: -1px;
            right: -15px;
            transform: translateX(100%);
            width: 300px;
            height: 235px;
            background-color: $modalColor;
            .patient-email {
              margin-top: 10px;
            }
            .user-email-info {
              color: $completed;
              &.error {
                color: red;
              }
            }
            input {
              margin-top: 30px;
            }
            div.buttons > * {
              box-sizing: border-box;
              margin-top: 30px;
              text-transform: uppercase;
              color: #fff;
              background-color: $accentTurquoise;
              font-size: 14px;
              line-height: 18px;
              height: 22px;
              font-weight: 500;
              padding: 2px 20px;
              border-radius: 4px;
            }
            &.parent-2 {
              right: -330px;
            }
            .parent-name {
              margin-top: 10px;
              font-weight: bold;
            }
          }
        }
      }
    }
    .section {
      box-sizing: content-box;
      color: $borderColor;
      font-weight: 700;
      background-color: $conversationBackground;
      border: 3px solid white;
      &:nth-last-child(1) {
      }
      .toggle-form-section:nth-last-child(1) {
        justify-content: flex-end;
        justify-self: end;
        align-self: center;
        width: 10px;
        height: 10px;
        padding: 5px;
        margin-right: 12px;
        border-radius: 50%;
        border: 2px solid $lightFontColor;
        background-color: white;
        cursor: pointer;

        img.rotate-180 {
          transition: 0.5s linear;
          transform: rotate(180deg);
        }
      }
    }
    .form-section-container {
      transition: 0.5s linear max-height;
      position: relative;
      max-height: 1000px;
      &.hidden {
        max-height: 0;
      }
    }
    .single-user-message {
      img {
        height: 15px;
        width: 20px;
      }
    }
  }
  .pagination-container {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    & > div {
      flex-basis: 50%;
      @include mq("main-table") {
        padding: 0 5px;
      }
    }
    .pagination-pages {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        font-size: 12px;
        color: $lightFontColor;
      }

      .pagination,
      ul {
        display: flex;

        button {
          width: 25px;
          height: 25px;
          background-color: $accentTurquoise;
          border: none;
          border-radius: 50%;
        }

        li {
          list-style-type: none;
          color: $accentTurquoise;
          font-size: 14px;
          margin-right: 5px;
          &.active {
            font-weight: bold;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      ul {
        margin: 0 15px;
        padding: 0;
        align-items: center;
      }
    }
  }
  .sort-action {
    &.rotate-180 {
      transform: rotate(180deg);
    }
  }
  .patient-list-img {
    height: 15px;
  }
  .patient-pdf-section {
    display: flex;
    justify-content: flex-end;
    .accent-button {
      margin-top: 20px;
      text-transform: uppercase;
      cursor: pointer;
      &:nth-last-child(1) {
        margin-left: 20px;
      }
    }
  }
  .doctor-item {
    position: relative;
    &-name {
      width: 100%;
      justify-content: left !important;
      cursor: pointer;
    }
    &-name-disabled {
      width: 100%;
      justify-content: left !important;
    }
    .doctor-detail-section {
      position: absolute;
      height: 225px;
      width: 400px;
      background-color: $modalColor;
      z-index: 999;
      top: 50%;
      right: -420px;
      transform: translateY(-50%) scale(0);
      transform-origin: 0 50%;
      transition: 0.35s;
      border: 1px solid $accentTurquoise;
      box-shadow: 0px 0px 10px 0px $basicFontColor;
      &.active {
        transform: translateY(-50%) scale(1);
      }
      //flex
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -15px;
        left: -30px;
        border: solid 15px transparent;
        border-right-color: $accentTurquoise;
      }
      .wrapper {
        width: 42%;
        padding: 2px 15px;
        text-align: left;
        color: $basicFontColor;

        .success {
          color: $accentTurquoise;
        }
        .error {
          color: red;
        }
        & > a {
          text-decoration: none;
          display: inline-block;
          width: 100%;
          padding-left: 5px;
          text-align: left;
          border-right-color: $basicFontColor;
        }
      }
      .modal-upper-border,
      .patient-detail-section > .header {
        position: absolute;
        right: -1px;
        left: -1px;
        top: -20px;
        bottom: 100%;
        background-color: $accentTurquoise;
        z-index: 999;
        color: white;
        text-transform: uppercase;
      }
      .x-button {
        position: absolute;
        height: 20px;
        width: 20px;
        right: 5px;
        top: -20px;
        z-index: 999;
        font-weight: bold;
        color: white;
        cursor: pointer;
      }
      .doctor-email {
        margin-top: 10px;
      }
      .doctor-info {
        color: $completed;
        &.error {
          color: red;
        }
      }
      input {
        margin-top: 30px;
      }
      div.buttons > * {
        box-sizing: border-box;
        margin-top: 30px;
        text-transform: uppercase;
        color: #fff;
        background-color: $accentTurquoise;
        font-size: 14px;
        line-height: 18px;
        height: 22px;
        font-weight: 500;
        padding: 2px 20px;
        border-radius: 4px;
      }
      .doctor-name {
        margin-top: 10px;
        font-weight: bold;
      }
      select {
        @include customSelect;
        margin-top: 40px;
        width: 60%;
      }
      .doctor-email-info {
        color: $completed;
        &.error {
          color: red;
        }
      }
      .edit-doctor-email {
        position: absolute;
        bottom: 20px;
        left: 50%;
        margin: 0 !important;
        transform: translateX(-50%);
      }
    }
  }
}
.no-access {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  color: $basicFontColor;
  font-weight: bold;
  .denied {
    transform: scale(2);
    margin-bottom: 20px;
  }
}

.row-error-img {
  height: 17px !important;
  width: 17px !important;
  place-self: center;
  margin-top: -2px;
}

.not-required {
  color: $lightFontColor !important;
  &section {
    display: grid;
    place-items: center;
  }
}

.form-not-required {
  color: $lightFontColor;
  font-size: 20px;
  .container {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    .fa {
      margin: 0 10px;
      font-size: 120%;
    }
  }
}

.form-container {
  position: relative;
  cursor: pointer;
  @include mq("main-table") {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .status-hovered {
    display: none;
  }
  .external-user {
    &:hover + .status-hovered {
      position: absolute;
      height: 100%;
      width: 400px;
      top: 0;
      right: 100px;
      display: grid;
      place-items: center;
      border-radius: 20px;
      color: white;
      z-index: 9;
      font-weight: bold;
      background-color: $borderColor;
    }
  }
  img.external-user-fill {
    height: 20px;
    margin-left: 5px;
  }
}

@include mq("main-table-from-mobile") {
  .desktop-to-hide {
    display: none !important;
  }
}
