body {
  font-family: azo-sans-web, sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

input,
button,
select,
textarea {
  font-family: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.relative-parent {
  position: relative;
}

.content-container {
  min-height: 100vh;

  &.light-bg {
    background-image: $lightGradient;
  }

  &.dark-bg {
    background-image: $darkGradient;
  }
}

nav {
  background-color: #fff;
  padding: 10px;
}

.container {
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.footer {
    justify-content: flex-end;
    div {
      margin-left: 20px;
    }
    a {
      cursor: pointer;
    }
  }
}

.content {
  min-height: calc(100vh - 91px);
}

.flex {
  display: flex;

  input {
    margin-bottom: 12px;
  }
}

section {
  max-width: 918px;
  margin: 0 auto;
  width: 100%;
  padding: 40px 0;
}

.basic-btn {
  padding: 12px 35px;
  @include basicButton;
  &.small {
    padding: 5px 20px;
    font-size: 12px;
  }
}

.basic-border {
  box-shadow: 0 0 20.16px 0.84px rgba(0, 0, 0, 0.05);
  padding: 40px 0;
  border-radius: 20px;
  background-color: #ebfdfc;
  margin: 0 auto;

  &.additional-margin {
    margin-bottom: 40px;
  }
}

h1,
h2 {
  text-align: center;
  color: $lightFontColor;
  margin-top: 0;
}

h1 {
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

h2 {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  font-weight: 300;
  color: $basicFontColor;
  text-transform: uppercase;
  text-align: center;

  &.small-font {
    font-size: 14px;
    text-transform: none;
    text-align: left;
  }

  &.cursive {
    font-style: italic;
    padding: 0 40px;
  }
}

.accent-button {
  font-size: 14px;
  background-color: $accentTurquoise;
  color: #fff;
  padding: 5px 20px;
  border-radius: 20px;
}

label {
  font-size: 14px;
  color: $basicFontColor;

  &.error {
    color: $errorRed;
  }
}

.accent {
  color: $lightFontColor;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea {
  border: 1px solid $borderColor;
  background-color: #fff;
  border-radius: 9px;
  font-size: 14px;
  padding: 5px 15px;
  position: relative;

  &:disabled {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &.fixed-width {
    max-width: 60px;
    margin-right: 40px;
  }

  &.small-padding {
    box-sizing: border-box;
    padding: 5px 40px;
    width: calc(100% - 80px);
    margin-bottom: 20px;
    &.width-auto {
      width: auto;
      display: flex;
      flex: auto;
    }
    @include mq("main-table") {
      width: 100%;
    }
  }

  &.small-padding-no-box {
    padding: 5px 40px;
    width: calc(100% - 80px);
    margin-bottom: 20px;
    &.width-auto {
      width: auto;
      display: flex;
      flex: auto;
    }
    &.plr10 {
      padding-left: 10px;
      padding-right: 10px;
      width: 55px !important;
    }
    @include mq("main-table") {
      width: 100%;
    }
  }

  &.width100 {
    width: 100%;
  }

  &.error {
    border: 1px solid $errorRed;

    & + .error-info {
      display: block;
    }

    &::placeholder {
      color: $errorRed;
    }
  }

  &::placeholder {
    color: $lightFontColor;
  }

  &:focus {
    outline: none;
  }

  &.large {
    width: 375px;
    margin-right: 10px;
    margin-bottom: 10px;
    @include mq("main-table") {
      width: 80vw !important;
    }
  }
}
@include mq("main-table") {
  .m-90-width {
    width: 90% !important;
  }
  .m-50-width {
    width: 50% !important;
  }
  .m-margin-left-0 {
    margin-left: 0 !important;
  }
}
textarea {
  resize: none;
  width: calc(100% - 20px);
  display: block;
  padding: 10px;
  margin: 5px 0 20px;
}

.error-info {
  display: none;
  position: absolute;
  right: -15px;
  top: 0;
  color: $errorRed;
  font-size: 25px;
}

.patient-name {
  margin-bottom: 10px;
}

.inputs-container {
  max-width: 610px;
  width: 100%;
  margin: 0 auto;
}

.paragraphs {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.main-logo {
  width: auto;
  height: 40px;
  cursor: pointer;
}

.basic-info {
  display: grid;
  grid-template-columns: 56% 43%;
  grid-gap: 60px;
  width: calc(100% - 60px);

  input {
    width: calc(100% - 30px);
    margin-bottom: 15px;
  }
}

.input-grid {
  display: grid;
  grid-template-columns: 40% 58%;
  max-width: calc(100% - 30px);
  grid-gap: 15px;
  margin-bottom: 15px;

  &.no-margin {
    margin-bottom: 0;
  }

  label {
    display: flex;
    align-items: center;

    &.ml-30 {
      margin-left: 30px;
    }

    &.cursive {
      font-style: italic;
    }
  }
}

.reset-password {
  text-align: center;
  color: $basicFontColor;
  &-confirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .basic-btn.mt-40 {
      margin-top: 40px;
    }
  }
  &-between {
    padding: 10px 0 !important;
  }

  & > p {
    padding: 40px 50px 10px;
  }
  span {
    font-size: 12px;
    display: inline-block;
    margin-top: 40px;
  }

  div &-btn {
    justify-content: center;
  }
}

.bullet {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;

  &::before {
    position: absolute;
    display: block;
    content: "";
    background-color: $accentTurquoise;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.btn-cnt {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;

  &.space-between {
    justify-content: space-between;
  }

  .basic-btn {
    background-color: $accentTurquoise;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    padding: 10px 40px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.fa {
  font-family: azo-sans-web, sans-serif;
}

.border-error {
  border: 1px solid red !important;
}

footer {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px 0;
  span,
  a {
    font-size: 12px;
    font-weight: 300;
    color: $lightFontColor;
  }
}

.progress-container {
  width: 100%;
  background-color: #e9fbfb;
  padding: 0;
  color: $lightFontColor;
  box-shadow: 0 2px 2px 1px rgba(208, 208, 208, 0.6);
}

.progress {
  color: $lightFontColor;

  &-navbar {
    max-width: 918px;
    margin: 0 auto;
    padding: 30px 0;
    color: $lightFontColor;

    .progress-bar {
      margin: 0 120px;
      @include mq("main-table") {
        margin: 0 10px;
      }
    }
  }
  &-bar {
    position: relative;
    box-sizing: border-box;
    height: 15px;
    margin: 40px 80px;
    border-radius: 12px;
    border: 3px solid $progressBarBorder;
    background-color: white;

    &-completed {
      height: 100%;
      border-radius: 12px;
      width: 0;
      background-image: $darkGradient;
      transition: 1s linear;
    }

    &-info {
      position: absolute;
      top: -25px;
      left: 0;
      transition: 1s linear;
    }
  }
}

.relative {
  position: relative;
}

.terms-and-conditions {
  width: 90%;
  margin: 10px auto;
  text-align: justify;
  span {
    font-weight: bold;
  }
}
.iti {
  width: calc(100% + 30px);
  margin-top: 0;
  margin-bottom: 20px;
  @include mq("main-table") {
    width: 100vw;
  }
}
#edit-patient-parent1-email,
#edit-patient-parent2-email {
  margin-bottom: 10px;
}

.iti__selected-dial-code,
.iti__country-name {
  color: black;
}
