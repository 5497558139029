.form.social-history,
.pagination-container,
.form-navigation {
  .form-container {
    padding: 40px 45px;
    margin: 0 auto;
    @include mq("main-table") {
      padding: 40px 5px;
      margin: 0 5px;
    }
  }

  .detailed-item-age {
    display: flex;
    justify-content: flex-end;
    select {
      margin-left: 5px;
    }
  }

  .plr10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .panel-container {
    margin-bottom: 20px;
  }

  span,
  label {
    font-size: 14px;
    font-weight: 300;
  }

  .address {
    margin-bottom: 30px;
    p {
      text-transform: none;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .content-form {
    p {
      text-transform: none;
      margin-bottom: 30px;
      text-align: left;
      font-size: 14px;
    }

    ul {
      font-size: 14px;

      li {
        list-style: none;
        padding: 10px 0;

        &::before {
          content: "\2022";
          color: $accentTurquoise;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }

        input {
          width: 30px;
          margin-left: 10px;
        }
      }
    }
    & .covid {
      max-width: 100%;
      & .custom-inputs {
        position: relative;

        & span.checkmark {
          position: absolute;
          top: 15%;
        }
      }
    }
  }
  .office-safety-precautions {
    & > p {
      border-bottom: 1px solid black;
      padding-bottom: 10px;
    }

    &-list {
      & li {
        list-style-type: disc;
      }
    }
  }

  .panel {
    background-color: $lightFontColor;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;

    h2 {
      color: #fff;
      font-weight: lighter;
      font-size: 16px;
      margin-bottom: 0;
    }

    button {
      border: none;
      background-color: inherit;
      margin-left: 20px;
      padding: 0;
    }
  }

  .hidden-content-container {
    margin-top: 20px;
  }

  .m40 {
    margin-top: 40px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .subtitle {
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .form-content {
    margin-top: 20px;
  }

  span.title {
    margin-bottom: 10px;
  }

  .flex {
    display: flex;
    align-items: baseline;

    label {
      display: flex;
      width: auto;
      margin-right: 15px;
    }

    input {
      width: 20%;
      max-height: 18px;
      @include mq("main-table") {
        max-height: inherit;
      }

      &.full-width {
        width: 100%;
      }

      &.width-unset {
        width: unset;
      }
    }
    &.space-between {
      justify-content: space-between;
    }
    &.input-margin {
      input {
        margin-left: 20px;
      }
    }
  }

  .mobile-desktop-display {
    display: flex;
    @include mq("custom-mobile") {
      display: block;
    }
  }

  .mobile-desktop-element {
    @include mq("custom-mobile") {
      width: 70%;
      justify-content: space-between;
    }
  }

  .inputs-container {
    max-width: unset !important;
    font-size: 14px;
    font-weight: lighter;
    margin-top: 10px;

    label,
    span {
      &.label220w {
        width: 220px;
      }
      &.no-margin {
        margin: 0 !important;
      }

      &.mr40 {
        margin-right: 40px;
      }

      &.uppercase {
        text-align: center;
        display: block;
        text-transform: uppercase;
      }

      &.indent {
        margin-left: 20px;
        display: block;
        @include mq("main-table") {
          margin-left: 0;
        }
      }

      &.block {
        display: block;
      }
    }
    &.mtop-40 {
      margin-top: 40px;
    }

    .max-width {
      width: 100%;
    }

    .additional-margin {
      margin: 0 40px 0 20px;
    }

    @include mq("main-table") {
      .m-without-margin {
        margin: 0;
      }
    }

    .select-related-doctor {
      padding-left: 30px;
    }

    &.mtop-20 {
      margin-top: 20px;
    }
    &.mbottom-20 {
      margin-bottom: 20px;
    }
    &.wrap {
      flex-wrap: wrap;
      & > div {
        margin-bottom: 30px;
      }
    }
    .top0 {
      margin-top: 0;
    }

    &.center {
      display: flex;
      justify-content: center;

      .radio-inputs {
        margin-bottom: 0;
        &.margin-top0 {
          margin-top: 0;
        }
      }
    }

    &.space-between {
      display: flex;
      justify-content: space-between;
    }

    label.block {
      margin-bottom: 10px;
      input {
        display: block;
        width: 80%;
        margin-top: 5px;
      }
    }

    &.basic-flex {
      display: flex;
      justify-content: left;
      align-items: center;
      margin-left: 0;

      &.baseline {
        align-items: baseline;
      }

      &.column {
        flex-direction: column;
        align-items: flex-start;

        input {
          width: 100%;
        }

        input[type="text"]:last-of-type {
          margin-bottom: 0;
        }
      }

      &.align-f-start {
        align-items: flex-start;
      }

      &.mt5 {
        margin-top: 5px;
      }

      label,
      span {
        margin-right: 15px;
      }
      .nested-flex {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
        &.full-width {
          width: 100%;
        }
        &.ml-40 {
          margin-left: 40px;
        }
        &.p-0-40 {
          padding: 0 40px;
        }
      }
      label.center {
        align-self: flex-start;
        margin-top: 10px;
      }
      label.half {
        width: 50%;
      }
      &.mt-20 {
        margin-top: 20px;
      }
      &.mt-40 {
        margin-top: 40px;
      }
      &.teacher-checklist {
        justify-content: center;
        .teacher-checklist-container {
          text-align: center;
          position: relative;
          input {
            &.error {
              border-color: red !important;
              color: red !important;
            }
          }
          .info {
            position: absolute;
            top: 50px;
            left: 0;
            font-size: 13px;
            &.success {
              color: $completed;
            }
            &.error {
              color: red;
            }
          }
          .buttons {
            display: flex;
            justify-content: center;
            .send {
              margin-top: 40px;
            }
          }
        }
      }
    }

    &.grid-two-columns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 17px;
      grid-row-gap: 0;

      &.gap {
        grid-template-columns: repeat(2, calc(50% - 13px));
        grid-gap: 25px;
      }
    }

    &.grid-three-columns-small {
      display: grid;
      grid-template-columns: 23% 23% 23%;
      grid-gap: 25px;
    }

    &.grid-three-columns {
      display: grid;
      grid-template-columns: 50% 20% 20%;
      grid-gap: 25px;

      &.even {
        grid-template-columns: repeat(3, 31%);
      }
      &.align-start {
        align-content: start;
      }
    }

    &.grid-five-columns {
      display: grid;
      grid-template-columns: 20% 25% 20% 17% 17%;
      &.even {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        place-items: center;
      }
      .self-left {
        place-self: start;
      }

      .table-header {
        text-align: center;
      }
      &.underline {
        border-bottom: 1px solid black;
        margin-left: 20px;
      }
    }

    @include mq("main-table") {
      .m-grid-two-columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 17px;
        grid-row-gap: 0;

        &.gap {
          grid-template-columns: repeat(2, calc(50% - 13px));
          grid-gap: 25px;
        }
      }
    }

    .title {
      display: flex;
      flex: none;
      margin-right: 40px;
    }
  }

  .custom-inputs {
    width: 130px;

    &.width-unset {
      width: unset;
    }
  }

  @include customInputs;

  .radio-inputs {
    display: flex;
    margin-bottom: 20px;
    max-width: 500px;
    margin-left: 20px;
    margin-top: 20px;
    justify-content: space-between;
    @include mq("main-table") {
      &.m-margin-left-0 {
        margin-left: 0;
      }
      &.m-flex-wrap {
        flex-wrap: wrap;
      }
      &.m-flex-end {
        justify-content: flex-end;
      }
    }
    &.flex-start {
      justify-content: flex-start;
    }

    &.checkboxes-cnt {
      display: flex;
      flex-wrap: wrap;
    }

    &.left {
      justify-content: flex-start;
    }

    &.columns {
      flex-direction: column;
    }

    &.max-width {
      max-width: unset;
    }

    @include mq("main-table") {
      &.m-width-auto {
        width: 100vw;
      }
    }

    &.no-margin {
      margin-bottom: 0;
      margin-left: 0;

      span {
        margin-bottom: 0;
      }
      &.margin-top0 {
        margin-top: 0;
      }
    }

    &.grid-columns {
      display: grid;
      grid-template-columns: 45% 15% 15%;
      max-width: 75%;
      grid-gap: 20px;
    }

    &.grid-four-columns {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 17px;
      margin-bottom: 0;
      margin-top: 20px;
      max-width: calc(80% - 20px);
      &.center {
        max-width: 100%;
      }
      label {
        margin-bottom: 0;
      }

      &.m40 {
        margin: 40px 0;
      }
      &.column150 {
        grid-template-columns: 110px 150px 100px 150px;
        place-self: center;
        @include mq("main-table") {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    @include mq("main-table") {
      &.m-grid-two-columns {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 17px;
        grid-row-gap: 17px;

        &.gap {
          grid-template-columns: repeat(2, calc(50% - 13px));
          grid-gap: 25px;
        }
      }
    }

    &.grid {
      display: grid;
      grid-template-columns: 15% 30% 50%;

      label {
        width: unset;
      }
    }
  }

  .checkboxes-inputs {
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    @include customInputs;
    @include mq("main-table") {
      margin-left: 0 !important;
      &.m-checkboxes-inputs-remove {
        display: block !important;
        margin-left: 0 !important;
      }
    }

    &.baseline {
      align-items: baseline;
    }

    &.no-margin {
      margin-bottom: 0;
    }
    &.center {
      margin-left: 0;
      align-items: center;
    }
  }

  .checkboxes-cnt {
    display: flex;
    flex-wrap: wrap;

    .custom-inputs {
      margin-right: 10px;
    }
  }

  .additional-info {
    padding: 25px 40px;

    &.left {
      padding: 0;

      p {
        text-align: left;
      }
    }

    p {
      text-align: center;
      text-transform: unset;
      font-size: 14px;
    }
  }

  select {
    @include customSelect;
  }
}
.text-input-inline {
  display: inline;
  width: 30%;
  margin: 0 5px;
}

span {
  &.bold {
    font-weight: bold !important;
  }
  &.mtb20 {
    margin: 20px 0;
  }
  &.inline-block {
    display: inline-block;
  }
}

.separate {
  margin: 50px 0;
  &-top {
    margin-top: 50px !important;
  }
}

.radio-inputs {
  &.mt-0 {
    margin-top: 0 !important;
  }
}

@include mq("main-table") {
  .m-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .m-grid-one-column {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr !important;
    grid-gap: 17px !important;
    grid-row-gap: 0 !important;

    &.gap {
      grid-template-columns: repeat(2, calc(50% - 13px));
      grid-gap: 25px;
    }
  }
}
