$breakpoints: (
  phone-small: (
    max-width: 460px,
  ),
  phone-large: (
    max-width: 640px,
  ),
  tablet-small: (
    max-width: 800px,
  ),
  tablet: (
    max-width: 1024px,
  ),
  desktop-small: (
    max-width: 1280px,
  ),
  desktop-medium: (
    max-width: 1600px,
  ),
  desktop-large: (
    max-width: 1900px,
  ),
  //CUSTOM BREAKPOINTS
    main-table:
    (
      max-width: 780px,
    ),
  main-table-from-mobile: (
    min-width: 781px,
  ),
  custom-mobile: (
    max-width: 480px,
  ),
);
@function mqFunc($breakpoint) {
  $size: map-get(
    $map: $breakpoints,
    $key: $breakpoint,
  );
  $max-width: map-get(
    $map: $size,
    $key: "max-width",
  );
  $min-width: map-get(
    $map: $size,
    $key: "min-width",
  );
  @if ($max-width) {
    @return "max-width: #{$max-width}";
  }
  @if ($min-width) {
    @return "min-width: #{$min-width}";
  }
}

@mixin mq($breakpoint) {
  @media (mqFunc($breakpoint)) {
    @content;
  }
}

.mobile-to-show {
  display: none !important;
}
@include mq(main-table) {
  .mobile-to-hide {
    display: none !important;
  }

  body {
    margin: 0 !important;
  }
}

@include mq(main-table-from-mobile) {
  .desktop-to-hide {
    display: none !important;
  }
  .desktop-to-show {
    display: initial !important;
  }
}
