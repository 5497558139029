.global-settings-loader {
  position: relative;
  top: 50%;
  left: 50%;
  width: 110px;
  text-align: center;
  transform: translateX(-50%);
}

.custom-loader {
  margin: 150px auto;
  font-size: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 50%;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display: grid;
  place-items: center;
  &.pdf-wait {
    margin: 0;
    top: 10px;
    left: 50%;
    font-size: 3px;
    width: 5px;
    height: 5px;
  }
}

.custom-loader2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display: grid;
  place-items: center;
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #10decc,
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.5),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.7),
      1.8em -1.8em 0 0em #10decc, 2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.5),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.7), 2.5em 0em 0 0em #10decc,
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.5),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.7), 1.75em 1.75em 0 0em #10decc,
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.5),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.7), 0em 2.5em 0 0em #10decc,
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.5),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.7), -1.8em 1.8em 0 0em #10decc,
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.5),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.7), -2.6em 0em 0 0em #10decc,
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.5),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.7), -1.8em -1.8em 0 0em #10decc;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #10decc,
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.5),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.7),
      1.8em -1.8em 0 0em #10decc, 2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.5),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.7), 2.5em 0em 0 0em #10decc,
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.5),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.7), 1.75em 1.75em 0 0em #10decc,
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.5),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.7), 0em 2.5em 0 0em #10decc,
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.2),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.5),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.7), -1.8em 1.8em 0 0em #10decc,
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.5),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.7), -2.6em 0em 0 0em #10decc,
      -1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(16, 222, 204, 0.2),
      1.8em -1.8em 0 0em rgba(16, 222, 204, 0.2),
      2.5em 0em 0 0em rgba(16, 222, 204, 0.2),
      1.75em 1.75em 0 0em rgba(16, 222, 204, 0.2),
      0em 2.5em 0 0em rgba(16, 222, 204, 0.2),
      -1.8em 1.8em 0 0em rgba(16, 222, 204, 0.5),
      -2.6em 0em 0 0em rgba(16, 222, 204, 0.7), -1.8em -1.8em 0 0em #10decc;
  }
}
