section {
  &.form-footer-management {
    display: flex;
    padding-top: 0;
    margin-top: -20px;

    select {
      box-sizing: content-box;
      padding: 12px 15px;
    }
    &.save-button {
      justify-content: center;
    }
    &.space-between {
      justify-content: space-between;
    }
    @include mq("main-table") {
      width: 100vw !important;
      flex-wrap: wrap;
      margin: -20px 5px 0 5px;
      .form-navigation {
        display: flex;
        flex-direction: column;
        align-content: center;
        margin: 0 auto;
        .send {
          margin-top: 5px;
        }
      }
    }
  }
}
