.ui-timepicker-container,
.ui-timepicker-standard {
  border: none !important;
  cursor: pointer;
  .ui-corner-all {
    color: $accentTurquoise;
  }
  .ui-state-hover {
    color: white !important;
    background-color: $lightFontColor !important;
    cursor: pointer;
  }
}
