.ui-datepicker {
  background: #fff;
  padding: 10px;
  width: 300px;
  z-index: 99999999 !important;
}

.ui-datepicker table td {
  text-align: center;
}

.ui-datepicker-calendar {
  width: 100%;
}
.ui-datepicker a {
  cursor: pointer;
  text-decoration: none;
}

.ui-datepicker-title {
  text-align: center;
  font-weight: bold;
}

.ui-widget.ui-widget-content {
  border: 1px solid $accentTurquoise;
}

.ui-datepicker th {
  text-align: center;
  font-weight: bold;
  color: $accentTurquoise;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  padding: 5px;
  font-weight: normal;
  color: $lightFontColor;
  display: block !important;
  border: 1px solid #fff;
}

a.ui-state-default:hover {
  border: 1px solid $accentTurquoise;
  color: black;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background-color: $accentTurquoise;
  color: #fff;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid $accentTurquoise;
  color: $basicFontColor;
}

.ui-widget-header {
  position: relative;
  width: 100%;
  border-bottom: 1px solid $lightFontColor;
  background: #fff;
  font-weight: bold;
  color: $basicFontColor;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .ui-datepicker-prev,
  .ui-datepicker-next {
    position: absolute;
  }
  .ui-datepicker-prev {
    left: 10px;
  }
  .ui-datepicker-next {
    right: 10px;
  }
}

.ui-datepicker-month,
.ui-datepicker-year {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid $accentTurquoise;
  background-color: #fff;
  color: black;
}

.ui-datepicker-trigger {
  height: 20px;
  color: white;
  margin-left: 5px;
}
img.ui-datepicker-trigger {
  transform: scale(0.8);
  cursor: pointer;
}

#ui-datepicker-div {
  display: none;
}
