.toolbar {
  position: fixed;
  top: 52px;
  bottom: 40px;
  right: 0;
  width: 500px;
  z-index: 1000;
  background-color: $basicFontColor;
  color: white;
  transition: 0.3s linear;
  transform: translate3d(100%, 0, 0);

  @include mq("phone-large") {
    width: 100%;
  }
  @include menuUserMargin;

  &.show {
    transform: translate3d(0, 0, 0);
  }

  .container {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      height: 20px;
      width: 20px;
      margin-right: 20px;

      &.no-margin {
        margin: 0;
      }

      &.spin {
        animation: spin 2s linear infinite;
      }
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }

    .option {
      display: flex;
      align-content: center;
      cursor: pointer;
      padding: 10px 50px;

      p {
        color: white;
        text-transform: uppercase;
        font-size: 15px;
        margin: 0;
        text-align: left;
      }
    }

    .section {
      width: 100%;

      &.section-top {
        min-height: 70px;
        border-bottom: 1px solid white;
        display: flex;
        justify-content: center;
        align-content: center;

        h3 {
          font-weight: normal;
          text-transform: uppercase;
          margin: auto;
        }
      }

      &.section-middle {
        @include customGreenScrollBar;
        width: calc(100% - 10px);
        flex-grow: 1;
        overflow-y: auto;

        div.option:nth-last-of-type(1) {
          padding: 10px 50px 50px 50px;
        }

        div.option:nth-of-type(1) {
          padding: 50px 50px 10px 50px;
        }
      }

      &.section-bottom {
        position: relative;
        bottom: 0;
        display: flex;
        flex-direction: row;
        border-top: 1px solid white;

        &.column {
          flex-direction: column;
        }

        &.row {
          align-items: center;
          justify-content: space-around;

          .option {
            padding: 20px 0;
          }
        }
      }
    }
  }
  .popup-wrapper {
    position: relative;

    .element-hovered {
      display: none;
    }

    .popup-hover-base {
      &:hover + .element-hovered {
        position: absolute;
        height: 25px;
        width: 400px;
        bottom: 30px;
        left: 20px;
        display: grid;
        place-items: center;
        border-radius: 20px;
        color: white;
        z-index: 999;
        font-weight: bold;
        background-color: $borderColor;
        border: 1px solid white;
      }
    }
  }
}
