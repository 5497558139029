.content-container.pdf-container {
  input:not([type="checkbox"]):not([type="radio"]) {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
  }

  .small-padding {
    padding: 5px 40px;
    margin-bottom: 20px;
  }
  .accent {
    color: #000;
  }
  .form-data,
  .form-data-span {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 9px;
    padding: 5px 40px;
    margin-bottom: 20px;
    margin-top: 5px;
    min-height: 20px;
  }

  span.form-data {
    display: block;
  }

  .form-data-span {
    display: inline-block;
  }

  .cursive {
    min-width: 250px;
  }
  span.bullet {
    border: 1px solid #ccc !important;

    &::before {
      background-color: #ccc !important;
    }
  }

  .basic-border {
    box-shadow: none;
    padding: 20px 0;
  }
  .basic-border.additional-margin {
    margin-bottom: 0;
  }

  section {
    margin-bottom: 0;
  }

  div.large {
    width: 375px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .form-data-span.block {
    display: block;
  }

  .basic-border {
    background-color: #fff;
  }

  .panel {
    background-color: #fff !important;
  }

  .panel h2 {
    color: #000 !important;
  }

  .checkmark {
    background-color: #fff;
    border: 1px solid #000 !important;
  }

  .checkmark.dot::after {
    background: #000 !important;
  }

  .checkmark.check::after {
    border-width: 0 3px 3px 0;
  }

  h1,
  h2 {
    color: #000;
  }
}
@font-face {
  font-family: AppleChancery;
  src: url(../fonts/AppleChanceryItalic.ttf);
}

.signature {
  font-family: AppleChancery;
}
