$basicFontColor: #3c5153;
$lightFontColor: #84b1b5;
$conversationBackground: #e9fbfb;
$conversationReceiverBackground: #10decc;
$conversationSenderBackground: #37c6e5;
$accentTurquoise: #00e1ce;
$completed: #01d401;
$progressBar: #39c5e3;
$progressBarBorder: #d4efef;
$listMarginBottom: #ebfdfc;
$borderColor: #84b1b5;
$textareaDisabled: darken(#f8f8f8, 5%);
$errorRed: #ff321d;
$modalColor: #dafcfb;
$lightGradient: linear-gradient(
  90deg,
  rgb(215, 255, 254) 0%,
  rgb(225, 245, 244) 39%,
  rgb(234, 234, 234) 100%
);
$darkGradient: linear-gradient(
  90deg,
  rgb(54, 200, 231) 0%,
  rgb(27, 213, 219) 39%,
  rgb(0, 225, 206) 99%
);
$adminGradent: linear-gradient(
  90deg,
  rgb(0, 97, 120) 0%,
  rgb(1, 140, 210) 39%,
  rgb(196, 5, 66) 99%
);
$adminGradent2: linear-gradient(
  90deg,
  rgb(229, 250, 250) 0%,
  rgb(228, 237, 252) 39%,
  rgb(222, 222, 222) 99%
);

input:not([type="checkbox"]):not([type="radio"]):disabled,
select[disabled],
textarea:disabled {
  background-color: $textareaDisabled !important;
}

input,
select,
textarea,
span.bullet {
  border: 1px solid $borderColor !important;
}
