.office {
  &.custom-select {
    @include customSelect;
    cursor: pointer;
    &.disabled {
      background-color: lightgray;
      cursor: unset;
      color: white;
    }
  }
}
