.patient-form {
  h1 {
    color: $accentTurquoise;
  }

  input {
    color: $lightFontColor;
  }

  .inputs-container.top {
    max-width: 836px;

    .basic-info {
      grid-template-columns: 62% 33%;
      grid-gap: 40px;
      width: 100%;
    }
  }

  .form-item {
    display: grid;
    grid-template-columns: 56% 44%;

    label {
      display: flex;
      align-items: center;
      height: 25px;
    }
  }

  .basic-btn {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.mar-top-25 {
  margin-top: 25px;
}
.mar-bot-25 {
  margin-bottom: 25px;
}
