.messages,
.messages-deleting {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 0;
  &.removed {
    display: none;
  }
}

.message-tooltip {
  background-color: #fff;
  padding: 10px;
  border: 1px solid $errorRed;
  margin: 0 auto 15px;
  border-radius: 20px;
  width: 620px;

  label {
    display: block;
    margin-bottom: 10px;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.message {
  width: 100%;
  border-radius: 20px;

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;

    span {
      font-weight: 600;
      margin-right: 5px;
    }

    img {
      margin-right: 20px;
    }
  }

  &.error {
    background-color: $errorRed;
  }

  &.info {
    background-image: $darkGradient;
  }
  &.success {
    background-image: $darkGradient;
  }
}

.alert {
  &.alert-error {
    color: red;
    &.referral {
      margin: -20px 0 40px 0;
    }
    &.mt-20 {
      margin-top: -20px;
    }
    &.full-width {
      grid-column: 1/-1;
    }
    &.hidden {
      display: none;
    }
    &.mb20 {
      margin-bottom: 20px;
    }
    &.fs13 {
      font-size: 13px;
    }
    &.align {
      text-align: center;
    }
    &.mt20 {
      margin-top: 20px;
    }
  }
}
