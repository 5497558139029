.dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
}

.dropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.dropdown-menu {
  position: absolute;
  transform: translateY(4px);
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
  max-height: 150px;
  background-color: $basicFontColor;
  z-index: 99;
}

.dropdown-item {
  padding: 5px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #84b1b5;
}

.dropdown-item.selected {
  background-color: #00e1ce;
  color: #fff;
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropdown-tag-item {
  background-color: #ddd;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.dropdown-tag-close {
  display: flex;
  align-items: center;
}

.search-box {
  padding: 5px;
}

.search-box input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.main-table-dropdown-container {
  background-color: white;
  border: 1px solid #84b1b4;
  border-radius: 16px !important;
  cursor: pointer;
  margin-left: 20px;
  padding-left: 15px;
  position: relative;
  text-align: left;
  width: 70px;

  .dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 150px;
    overflow: auto;
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    z-index: 99;
  }

  .dropdown-item.selected {
    background-color: #ccc;
    color: #fff;
  }

  .dropdown-input {
    color: #84b1b5;
  }

  .dropdown-item:hover {
    background-color: rgb(241, 241, 241);
    color: black;
  }
}

.main-table-dropdown-doctor-filter {
  background-color: #84b1b4;
  border-radius: 10px !important;
  cursor: pointer;
  display: flex;
  padding: 0 10px 0 15px;
  position: relative;
  text-align: left;
  min-width: 110px;
  max-width: 200px;
  height: 46px;
  text-transform: uppercase;

  .dropdown-selected-value {
    color: white !important;
    font-size: 12px;

    p {
      color: white !important;
    }
  }

  .dropdown-tool {
    display: none;
  }

  .dropdown-item.selected {
    background-color: #ccc;
    color: #fff;
  }

  .dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 300px;
    overflow: auto;
    position: absolute;
    transform: translateY(4px);
    width: 200%;
    z-index: 3;
    top: 35px;
    left: 0;
  }

  .dropdown-input {
    color: #84b1b5;
    width: 100%;
  }

  .dropdown-item:hover {
    background-color: rgb(241, 241, 241);
    color: black;
  }
}
