@mixin customInputs {
  .custom-inputs {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    &.mr20 {
      margin-right: 20px;
    }
  }

  label {
    cursor: pointer;
    width: 100%;

    &.fixed-width {
      width: 130px;
    }
    &.short {
      width: 100px;
    }
  }

  /* Hide the browser's default radio button */
  .custom-inputs input:not([type="text"]) {
    position: absolute;
    opacity: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid $borderColor;

    &.r-only {
      background-color: #f8f8f8;
      cursor: not-allowed;
    }

    &.center {
      left: 50%;
    }

    &.dot {
      border-radius: 50%;
    }
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .checkmark.active:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark {
    &.dot:after {
      width: 7px;
      height: 7px;
      top: 4px;
      left: 4px;
      border-radius: 50%;
      background: $accentTurquoise;
    }

    &.check:after {
      width: 5px;
      height: 7px;
      left: 4px;
      top: 2px;
      border: solid $accentTurquoise;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

@mixin customSelect {
  border: 1px solid $borderColor;
  background-color: #fff;
  color: $lightFontColor;
  border-radius: 12px;
  min-width: 60px;
  padding: 5px 15px;
  font-size: 12px;
  max-height: 30px;
  @include mq("main-table") {
    min-width: unset;
  }
}

@mixin basicButton {
  display: block;
  border: none;
  background-color: $accentTurquoise;
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;
  border-radius: 20px;

  &.disabled {
    background-color: darkgray;
    color: lightgray;
  }
}

@mixin customScrollBar {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

@mixin customGreenScrollBar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $lightFontColor;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5) !important;
    border-radius: 100px;
  }
}

@mixin menuUserMargin {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

@mixin menuButton {
  .menu-button {
    text-transform: uppercase;
    color: #fff;
    background-color: $accentTurquoise;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 20px;
    border-radius: 4px;
    border: unset;
    &.delete {
      background-color: red;
      text-align: center;
    }
  }
}
