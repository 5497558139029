.archived-account {
  width: 575px;
  height: 290px;
  padding-top: 100px;
  .info {
    color: $basicFontColor;
    width: 80%;
    text-align: center;
    margin: 40px auto;
    a {
      text-decoration: none;
      color: $conversationSenderBackground;
    }
  }
  .webiste.basic-btn {
    margin: 100px auto 20px auto;
    text-align: center;
  }
}
