.form.small-form {
  display: flex;
  justify-content: center;
  max-width: 576px;
  padding-top: 100px;

  &.wide {
    max-width: 920px;

    .inputs-container {
      max-width: 810px;
    }
  }

  .errorlist {
    color: $errorRed;
    text-transform: lowercase;
  }

  .error-box {
    margin-bottom: 15px;
  }

  .relative-parent {
    position: relative;
    width: 100%;

    input {
      width: calc(100% - 50px);
    }
  }

  span.error {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    color: $errorRed;
  }

  form {
    width: 100%;
  }

  .form-container {
    margin-top: 0;
  }

  .inputs-container {
    max-width: 382px;
    display: flex;
    flex-direction: column;

    input {
      padding: 13px 25px;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }

  .form-actions {
    max-width: 382px;
    margin: 0 auto;
    text-align: right;
  }

  .forgot-pass,
  .delete-parent {
    background-color: inherit;
    font-size: 14px;
    font-weight: 300;
    color: $accentTurquoise;
  }

  .login-btn {
    margin-bottom: 0;
    button {
      @include basicButton;
      width: 100%;
      padding: 13px 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.form-parent {
  margin-top: 20px;
}

.form-patient {
  select {
    @include customSelect;
  }
  .mr30 {
    margin-right: 30px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
}
